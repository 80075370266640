import { CSSProperties, useState } from "react";
import { colors } from "../../theme";
import { Outlet } from "react-router-dom";
import { Link } from "react-router-dom";

interface TabsProps {
  tabItems: { name: string; routePath: string }[];
}

const Tabs = ({ tabItems }: TabsProps) => {
  const styles = buildStyle();

  const [activeItem, setActiveItem] = useState<number>(0);

  return (
    <div style={styles.tabsContainer}>
      <div style={styles.tabItemContainer}>
        {tabItems.map((tabItem, idx) => (
          <Link
            to={tabItem.routePath}
            style={activeItem === idx ? styles.tabItemActive : styles.tabItem}
            key={idx}
            onClick={() => {
              setActiveItem(idx);
            }}
          >
            {tabItem.name}
          </Link>
        ))}
      </div>
      <div style={styles.tabContent}>
        <Outlet />
      </div>
    </div>
  );
};

const buildStyle = (): Record<string, CSSProperties> => {
  return {
    tabsContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      height: "100%",
    },
    tabItemContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-start",
      columnGap: "12px",
      marginBottom: "20px",
      flex: "0 0 30px",
    },
    tabItemActive: {
      textDecoration: "none",
      backgroundColor: "transparent",
      border: "0px",
      borderBottom: "5px solid",
      borderBottomColor: colors.primaryPink,
      paddingBottom: "5px",
      textShadow: `0px 0px 1px ${colors.primaryPink}`,
      color: colors.primaryPink,
      fontSize: "16px",
    },
    tabItem: {
      textDecoration: "none",
      backgroundColor: "transparent",
      border: "0px",
      borderBottom: "5px solid",
      borderBottomColor: colors.primaryGray,
      paddingBottom: "5px",
      fontSize: "16px",
    },
    tabContent: {
      width: "100%",
      height: "100%",
      flexGrow: "1",
      minHeight: "0px",
    },
  };
};

export default Tabs;
