import { CSSProperties, useState } from "react";
import { Outlet } from "react-router-dom";
import SideBarMenuItems from "../../components/common/SideBarMenuItems";
import { boxShadow, colors } from "../../theme";

export default function AdminLayout() {
  const styles = buildStyle();
  const [contentHeader, setContentHeader] = useState<string>("");
  return (
    <div style={styles.pageContainer}>
      <div style={styles.sideBarContainer}>
        <div style={styles.barHeaderContainer}>
          <h2 style={styles.headerText}>{"Nail Suite Tools"}</h2>
        </div>
        <SideBarMenuItems
          menuItems={[
            {
              url: "/admin",
              title: "Home",
              onClick: () => {
                setContentHeader("Home");
              },
            },
            {
              url: "/admin/emails/templates",
              title: "Email Manager",
              onClick: () => {
                setContentHeader("Email Manager");
              },
            },
            {
              url: "/admin/forms",
              title: "Form Submissions",
              onClick: () => {
                setContentHeader("Form Submissions");
              },
            },
          ]}
        />
      </div>
      <div style={styles.contentContainer}>
        <header style={styles.headerContainer}>
          <h2 style={styles.headerTitle}>{contentHeader}</h2>
          <div style={styles.login}>Login</div>
        </header>
        <div style={styles.mainContent}>
          <Outlet />
        </div>
      </div>
    </div>
  );
}

const buildStyle = (): Record<string, CSSProperties> => {
  return {
    pageContainer: {
      display: "flex",
      flexDirection: "row",
      height: "100vh",
    },
    sideBarContainer: {
      flex: "0 0 300px",
      display: "flex",
      flexDirection: "column",
      height: "100%",
      backgroundColor: colors.primaryPink,
      boxShadow: boxShadow.leftNav,
      zIndex: 0,
    },
    contentContainer: {
      flexGrow: "1",
      backgroundColor: colors.backgroundGray,
      height: "100%",
      display: "flex",
      flexDirection: "column",
    },
    headerText: {
      color: colors.foregroundWhite,
      margin: 0,
      marginLeft: 20,
    },
    barHeaderContainer: {
      height: "70px",
      width: "100%",
      borderBottom: "1px solid",
      borderBottomColor: colors.foregroundWhite,
      display: "flex",
      alignItems: "center",
    },
    headerContainer: {
      width: "100%",
      borderBottom: "1px solid",
      borderBottomColor: colors.primaryGray,
      backgroundColor: colors.foregroundWhite,
      flex: "0 0 70px",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    headerTitle: {
      margin: 0,
      marginLeft: 20,
    },
    mainContent: {
      padding: "20px",
      flexGrow: "1",
      minHeight: 0,
      maxHeight: "calc(100% - 70px)",
    },
    login: {
      marginRight: 30,
    },
  };
};
