import { ReactNode } from "react";
import "./Table.css";
import { JsxElement } from "typescript";

interface TableProps {
  columns: string[];
  rows: (string | number | ReactNode | undefined)[][];
}

const Table = ({ columns, rows }: TableProps) => {
  return (
    <table className="styled-table">
      <thead>
        <tr>
          {columns.map((column) => (
            <th>{column}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {rows.map((row) => {
          return (
            <tr>
              {row.map((cell) => {
                return <td>{cell}</td>;
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default Table;
