import React, { CSSProperties, useMemo, useState } from "react";
import Card from "../../common/Card";
import List from "../../common/List";
import { EmailTemplate } from "../../../utils/types";
import { colors, fontWeight } from "../../../theme";
import parse from "html-react-parser";
import Button from "../../common/Button";
import EditIcon from "../../../images/icons/pen-to-square-regular.svg";
import TrashIcon from "../../../images/icons/trash-can-regular.svg";

interface EmailTemplatesProps {
  templates: EmailTemplate[];
}

const EmailTemplates = ({ templates }: EmailTemplatesProps) => {
  const styles = buildStyle();

  const [selectedTemplate, setSelectedTemplate] = useState<EmailTemplate>();

  const templateListItems = useMemo(() => {
    return templates.map((template) => ({
      id: template.id || "",
      label: template.name,
    }));
  }, [templates]);

  const getTemplateContentParsed = (
    content: string | undefined
  ): JSX.Element => {
    if (!content) {
      return <></>;
    }
    const modifiedContent = content.replace(
      /\{\{(\w+)\}\}/g,
      '<strong style="color:' + colors.primaryPink + ';">$1</strong>'
    );
    return <>{parse(modifiedContent)}</>;
  };

  return (
    <div style={styles.cardContainer}>
      <div style={styles.leftColumn}>
        <Card style={styles.templatesListCard}>
          <List
            listItems={templateListItems}
            listTitle={"Templates"}
            onClickItem={(id) => {
              setSelectedTemplate(templates.find((t) => t.id === id));
            }}
            hasActiveItem
            sort
          />
        </Card>
      </div>
      <div style={styles.rightColumn}>
        <Card style={styles.titleCard}>
          <div style={styles.titleCardTitle}>{selectedTemplate?.name}</div>
          <div style={styles.titleBarActionsContainer}>
            <Button icon={EditIcon} type={"secondary"} overridePadding={0} />
            <Button icon={TrashIcon} type={"secondary"} overridePadding={0} />
          </div>
        </Card>
        <Card style={styles.contentCard}>
          <div style={styles.subjectContainer}>
            <div style={styles.subjectLabel}>{"Subject"}</div>
            <div style={styles.subjectValue}>{selectedTemplate?.subject}</div>
          </div>
          <div style={styles.templateContainer}>
            <div style={styles.templateLabel}>{"Email Content"}</div>
            <div style={styles.templateValue}>
              {getTemplateContentParsed(selectedTemplate?.content)}
            </div>
          </div>
        </Card>
      </div>
    </div>
  );
};

const buildStyle = (): Record<string, CSSProperties> => {
  return {
    cardContainer: {
      display: "flex",
      flexDirection: "row",
      gap: "20px",
      height: "100%",
      width: "100%",
    },
    leftColumn: {
      height: "100%",
      flex: "0 0 350px",
    },
    rightColumn: {
      display: "flex",
      flexDirection: "column",
      gap: "20px",
      height: "100%",
      flexGrow: "1",
    },
    templatesListCard: {
      height: "100%",
    },
    titleCard: {
      flex: "0 0 90px",
      padding: "30px",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    titleCardTitle: {
      fontSize: 24,
      fontWeight: fontWeight.bold,
    },
    titleBarActionsContainer: {
      display: "flex",
      gap: 10,
    },
    contentCard: {
      flexGrow: "1",
      padding: "30px",
      paddingTop: "40px",
      minHeight: 0,
      maxHeight: "calc(100% - 90px)",
      overflowY: "auto",
    },
    subjectContainer: {
      display: "flex",
      gap: "10px",
      marginBottom: "15px",
    },
    subjectLabel: {
      fontWeight: fontWeight.bold,
      marginBottom: "18px",
    },
    templateContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
    },
    templateLabel: {
      fontWeight: fontWeight.bold,
      marginBottom: "18px",
    },
    templateValue: {
      textWrap: "wrap",
    },
  };
};

export default EmailTemplates;
