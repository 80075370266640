import React, { useState } from "react";
import Form from "../../common/Form/Form";
import FormSection from "../../common/Form/FormSection";
import ShortAnswer from "../../common/Form/ShortAnswer";
import { useNavigate } from "react-router-dom";
import { addForm } from "../../../clients/FormClient";
import MultipleChoice from "../../common/Form/MultipleChoice";
import MultiSelect from "../../common/Form/MultiSelect";
import ImageSelect from "../../common/Form/ImageSelect";
import LongAnswer from "../../common/Form/LongAnswer";

const ClassRequestForm = () => {
  const [fullName, setFullName] = useState<string>("");
  const [instagramHandle, setInstagramHandle] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [ranking, setRanking] = useState<string>("");
  const [strengths, setStrengths] = useState<string>("");
  const [goals, setGoals] = useState<string>("");

  const navigate = useNavigate();

  return (
    <Form
      title={"Let's Plan Your 1:1!"}
      description={
        <>
          <p>
            <span style={{ color: "rgb(32, 33, 36)" }}>
              Before all else, THANK YOU for trusting me to teach you! I am
              honored &amp; thrilled to see what we accomplish together. Here's
              what you need to know about scheduling your class:
            </span>
          </p>
          <p></p>
          <ul>
            <li>
              <strong>WHAT WE'LL COVER</strong> - Classes are completely
              customized to what you want to learn. You can do a combination of
              any of the following topics: <em>Nail Prep &amp; Shaping</em>,{" "}
              <em>Base &amp; Color Application</em>, <em>Trendy Nail Art</em>,{" "}
              <em>Special Effect Nail Art</em>, <em>Advanced Nail Art</em>,
              and/or <em>Photography &amp; Editing</em>.
            </li>
            <li>
              <strong>WHAT TO BRING</strong> - Nothing! For any of the topics,
              you will use all of my supplies; so don't worry about bringing any
              to class unless you have questions about certain products/brushes
              you have or want to bring a pen &amp; paper for notes.
            </li>
            <li>
              <strong>MODELS </strong>- You will not need a model for class. If
              you would prefer to work on a model for{" "}
              <em>Nail Prep &amp; Shaping</em> rather than your own hand, then
              please bring a model! Just make sure they are willing to sit for
              several hours &amp; know we will not have time for a full set.{" "}
              <strong>
                If you will be working on yourself, make sure your nails are
                sufficiently grown out so we can safely &amp; accurately go
                through the process. No need to remove your product beforehand
                (unless it's acrylic, dip, or gel-x).&nbsp;
              </strong>
            </li>
            <li>
              <strong>CLASS MATERIAL&nbsp;</strong>- After the class I will
              email you two things, 1) review documents on what we covered &amp;
              2) links to everything we used in class.
            </li>
            <li>
              <strong>PRICING </strong>- Classes are $90/hour. Based on the
              information you provide below, I will give a recommended amount of
              time for the class. I am happy to do less than the recommended
              time; it will just be less in-depth.
            </li>
            <li>
              <strong>DEPOSIT </strong>- I do require a 25% non-refundable
              deposit upon booking. Once we decide the class length, I will let
              you know your deposit amount. This will go toward the class total.
            </li>
            <li>
              <strong>RESCHEDULING</strong> - You may reschedule up to 48 hours
              (2 days) before your class with no penalty. If you reschedule
              within that time, your deposit will not transfer &amp; you will
              need to pay it again.
            </li>
            <li>
              <strong>CANCELLATIONS</strong>&nbsp;- You may cancel up to 48
              hours (2 days) before your class with no penalty. If you cancel
              within that time, you will be charged 50% of your class total. No
              shows will be charged 100%.
            </li>
            <li>
              <strong>SICK/EMERGENCIES</strong> - If you are sick or have an
              emergency, the rescheduling &amp; cancellation policies are void.
              If <em>I </em>am sick or have an emergency, you will obviously not
              be penalized. :)
            </li>
          </ul>
          <p></p>
          <p>
            Answering the questions below will help me know how to organize our
            time together &amp; make sure you get the most out of your money. So
            let's have fun! &lt;3
          </p>
        </>
      }
      formWidth={800}
      bannerImg="https://imgix.bustle.com/uploads/image/2023/6/28/d195ea53-1c38-42e5-b16d-c0393fe5899d-img_0972.jpg?w=414&h=372&fit=crop&crop=faces&q=50&dpr=2"
      onSubmit={async () => {
        // const newId = await addForm({
        //   firstName,
        //   lastName,
        //   email,
        //   phone,
        // });
        // navigate(`/one-on-one/form/${newId}`);
      }}
      pages={[
        <>
          <FormSection>
            <ShortAnswer
              value={fullName}
              name={"fullName"}
              onChange={(newValue) => {
                setFullName(newValue);
              }}
              label={"Full Name"}
            />
            <ShortAnswer
              value={instagramHandle}
              name={"instagramHandle"}
              onChange={(newValue) => {
                setInstagramHandle(newValue);
              }}
              label={"Instagram handle"}
            />
            <ShortAnswer
              value={email}
              name={"email"}
              onChange={(newValue) => {
                setEmail(newValue);
              }}
              label={"Best email for documents & links"}
            />
            <ShortAnswer
              value={phone}
              name={"phone"}
              onChange={(newValue) => {
                setPhone(newValue);
              }}
              label={"Phone number to book the class with"}
            />
          </FormSection>
          <FormSection>
            <MultiSelect
              question="Which day(s) work best for you? (Select all that apply. I don't work Saturday or Sunday)"
              choices={[
                { label: "Monday", value: "monday" },
                { label: "Tuesday", value: "tuesday" },
                { label: "Wednesday", value: "wednesday" },
                { label: "Thursday", value: "thursday" },
                { label: "Friday", value: "friday" },
              ]}
            />
            <MultipleChoice
              question="Which time of day works best for you? (I don't teach in the evening)"
              choices={[
                { label: "Mornings", value: "morning" },
                { label: "Afternoons", value: "afternoon" },
                { label: "Either", value: "either" },
              ]}
            />
          </FormSection>
          <FormSection>
            <ImageSelect
              question="Which topic(s) would you like to cover in our class? (Select all that apply)"
              choices={[
                {
                  imgSrc:
                    "https://cdn.psychologytoday.com/sites/default/files/styles/article-inline-half-caption/public/field_blog_entry_images/2020-06/angry_chihuahua.png?itok=TWxYDbOT",
                  label:
                    "Nail Prep & Shaping (natural nail prep, removal, cuticle work, specialty shaping)",
                  value: "1",
                },
                {
                  imgSrc:
                    "https://www.hartz.com/wp-content/uploads/2022/04/small-dog-owners-1.jpg",
                  label:
                    "Base & Color Application (perfecting your apex, polishing 'under' the cuticle)",
                  value: "2",
                },
                {
                  imgSrc:
                    "https://d3544la1u8djza.cloudfront.net/APHI/Blog/2021/07-06/small+white+fluffy+dog+smiling+at+the+camera+in+close-up-min.jpg",
                  label:
                    "Trendy Nail Art (checkers, flames, smiley faces, French tip, swirls, etc.)",
                  value: "3",
                },
                {
                  imgSrc:
                    "https://www.nylabone.com/-/media/project/oneweb/nylabone/images/dog101/activities-fun/10-great-small-dog-breeds/maltese-portrait.jpg",
                  label:
                    "Special Effect Nail Art (chrome, cat eye, aura, ombre, marble, watercolor, 3D/embossing, croc, etc.)",
                  value: "4",
                },
                {
                  imgSrc:
                    "https://www.nylabone.com/-/media/project/oneweb/nylabone/images/dog101/activities-fun/10-great-small-dog-breeds/maltese-portrait.jpg",
                  label:
                    "Advanced Nail Art (breaking down a character or design, visualizing layers,  proportions, executing art)",
                  value: "5",
                },
                {
                  imgSrc:
                    "https://www.nylabone.com/-/media/project/oneweb/nylabone/images/dog101/activities-fun/10-great-small-dog-breeds/maltese-portrait.jpg",
                  label:
                    "Photography & Editing (positioning hands, editing to showcase your work, adding watermark)",
                  value: "5",
                },
              ]}
            />
          </FormSection>
          <FormSection>
            <ShortAnswer
              value={ranking}
              name={"ranking"}
              onChange={(newValue) => {
                setRanking(newValue);
              }}
              label={
                "Please rank the topics you just selected in order of importance, the first being the most important."
              }
            />
          </FormSection>
          <FormSection>
            <LongAnswer
              value={strengths}
              name={"strengths"}
              onChange={(newValue) => {
                setStrengths(newValue);
              }}
              label={
                "What are your biggest strengths as a nail tech? I want to hear you brag! ;)"
              }
            />
            <MultiSelect
              question="What type of learner are you? (Select all that apply)"
              choices={[
                {
                  label:
                    "Visual; I learn best through SEEING (videos, demos, drawings, color coding, charts, etc.)",
                  value: "visual",
                },
                {
                  label:
                    "Auditory; I learn best through LISTENING (lecture, discussion, songs, etc.)",
                  value: "auditory",
                },
                {
                  label:
                    "Reading/Writing; I learn best through READING/WRITING (taking notes, doodling, defining terms, etc.)",
                  value: "reeading",
                },
                {
                  label:
                    "Kinesthetic; I learn best through DOING (practice, trial & error, mimicking, using sight/touch/taste/smell/sound, etc.)",
                  value: "kinesthetic",
                },
                {
                  label: "I'm honestly not sure! Just here for the ride.",
                  value: "none",
                },
              ]}
            />
            <ShortAnswer
              value={goals}
              name={"goals"}
              onChange={(newValue) => {
                setGoals(newValue);
              }}
              label={
                "What are the TOP 3 things you hope to feel confident in by the end of this class?"
              }
            />
          </FormSection>
        </>,
        <>
          <FormSection>
            <MultipleChoice
              question="Which of the following appointments works best for you?"
              choices={[
                { label: "Tuesday, June 20 @ 10am", value: "1" },
                { label: "Wednesday, June 21 @ 9am", value: "2" },
                { label: "Thursday, June 22 @ 10am", value: "3" },
              ]}
            />
          </FormSection>
        </>,
      ]}
    ></Form>
  );
};

export default ClassRequestForm;
