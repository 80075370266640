import { CSSProperties, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { colors } from "../../theme";

interface SideBarMenuItemsProps {
  menuItems: { title: string; url: string; onClick: () => void }[];
}

const SideBarMenuItems = ({ menuItems }: SideBarMenuItemsProps) => {
  const styles = buildStyle();

  const { pathname } = useLocation();
  const [activeItem, setActiveItem] = useState<number>();

  useEffect(() => {
    const initialActiveItem =
      menuItems.findIndex(
        ({ url }) => url === pathname || url + "/" === pathname
      ) ?? 0;
    setActiveItem(initialActiveItem);
  }, []);

  return (
    <div style={styles.menuItems}>
      {menuItems.map((menuItem, idx) => (
        <div
          key={menuItem.title}
          style={activeItem === idx ? styles.menuItemActive : styles.menuItem}
        >
          <Link
            to={menuItem.url}
            style={activeItem === idx ? styles.navLinkActive : styles.navLink}
            key={menuItem.url}
            onClick={() => {
              menuItem.onClick();
              setActiveItem(idx);
            }}
          >
            {menuItem.title}
          </Link>
        </div>
      ))}
    </div>
  );
};

const buildStyle = (): Record<string, CSSProperties> => {
  return {
    menuItems: {
      display: "flex",
      flexDirection: "column",
    },
    menuItem: {
      display: "flex",
      justifyContent: "flex-start",
      paddingLeft: 20,
      alignItems: "center",
      height: "60px",
      width: "100%",
    },
    menuItemActive: {
      display: "flex",
      justifyContent: "flex-start",
      paddingLeft: 20,
      alignItems: "center",
      height: "6S0px",
      width: "100%",
      backgroundColor: colors.foregroundWhite,
      boxSizing: "border-box",
    },
    navLink: {
      color: colors.foregroundWhite,
      fontSize: 18,
      textDecoration: "none",
    },
    navLinkActive: {
      color: colors.primaryPink,
      fontSize: 18,
      textDecoration: "none",
    },
  };
};

export default SideBarMenuItems;
