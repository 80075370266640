import React from "react";
import Tabs from "../../common/Tabs";
import EmailTemplates from "./EmailTemplates";
import Emails from "./Emails";
import EmailAttachments from "./EmailAttachments";

const EmailManager = () => {
  return (
    <>
      <Tabs
        tabItems={[
          {
            name: "Templates",
            routePath: "/admin/emails/templates",
          },
          {
            name: "Emails",
            routePath: "/admin/emails/send",
          },
          {
            name: "Attachments",
            routePath: "/admin/emails/attachments",
          },
        ]}
      />
    </>
  );
};

export default EmailManager;
