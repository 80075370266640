import React, { CSSProperties, useEffect, useState } from "react";
import { FormSubmission, WaitlistProfile } from "../../utils/types";
import LoadingSpinner from "../../components/common/LoadingSpinner/LoadingSpinner";
import Table from "../../components/common/Table/Table";
import Button from "../../components/common/Button";
import { getAllForms } from "../../clients/FormClient";

const buildStyle = (): Record<string, CSSProperties> => {
  return {
    formsContainer: {
      display: "flex",
      justifyContent: "center",
    },
  };
};

const FormsAdminPage = () => {
  const styles = buildStyle();

  const [formSubmissions, setFormSubmissions] = useState<FormSubmission[]>();

  useEffect(() => {
    async function fetchData() {
      let response = await getAllForms();
      setFormSubmissions(response);
    }
    fetchData();
  }, []);

  return (
    <div style={styles.formsContainer}>
      {formSubmissions ? (
        <Table
          columns={["First Name", "Last Name", "Email", "Phone", "ID", ""]}
          rows={formSubmissions?.map((formSubmission) => {
            return [
              formSubmission.firstName,
              formSubmission.lastName,
              formSubmission.email,
              formSubmission.phone,
              formSubmission.id,
              <>
                <Button
                  text="Delete"
                  onClick={async () => {
                    // waitlistProfile.id &&
                    //   (await deleteWaitlistProfile(waitlistProfile.id));
                    // setWaitlistProfiles(await getAllWaitlistProfiles());
                  }}
                />
              </>,
            ];
          })}
        />
      ) : (
        <LoadingSpinner />
      )}
    </div>
  );
};

export default FormsAdminPage;
