import React, { CSSProperties } from "react";
import { colors } from "../../theme";

interface ButtonProps {
  text?: string;
  icon?: string;
  onClick?: () => void;
  type?: "primary" | "secondary";
  overridePadding?: number;
}

const Button = ({
  text,
  icon,
  onClick,
  type = "primary",
  overridePadding,
}: ButtonProps) => {
  const styles = buildStyle(type, overridePadding);
  return (
    <>
      <button style={styles.button} onClick={onClick}>
        {!!icon && <img src={icon} width={30} height={30} />}
        {!!text && text}
      </button>
    </>
  );
};

const buildStyle = (
  type: string,
  overridePadding: number | undefined
): Record<string, CSSProperties> => {
  let color;
  let border;
  let backgroundColor;

  if (type === "primary") {
    color = colors.foregroundWhite;
    border = 0;
    backgroundColor = colors.secondaryPink;
  } else {
    color = colors.primaryGray;
    border = 0;
    backgroundColor = "transparent";
  }
  return {
    button: {
      padding: overridePadding ?? 10,
      backgroundColor,
      borderRadius: 10,
      color,
      border,
      fontSize: 16,
      cursor: "pointer",
      display: "flex",
      gap: "8px",
    },
  };
};

export default Button;
