import React, { useEffect, useState } from "react";
import { useLoaderData } from "react-router-dom";
import { FormSubmission } from "../../utils/types";
import { getForm } from "../../clients/FormClient";
import ClassConfirmation from "../../components/guest/classRequest/ClassConfirmation";

export const loader = async ({ params }: { params: any }) => {
  const formSubmission = await getForm(params.formId);
  return { formSubmission };
};

const ClassConfirmationPage = () => {
  const { formSubmission } = useLoaderData() as {
    formSubmission: FormSubmission;
  };

  return <ClassConfirmation formSubmission={formSubmission} />;
};

export default ClassConfirmationPage;
