import { CSSProperties, ReactNode, useState } from "react";
import Button from "../Button";
import FormSection from "./FormSection";
import useBreakpoints from "../../../utils/hooks/useBreakpoints";
import { boxShadow } from "../../../theme";

interface FormProps {
  pages: ReactNode[];
  onSubmit: () => void;
  title: string;
  description: string | ReactNode;
  formWidth?: number;
  bannerImg?: string;
}

const Form = ({
  pages,
  onSubmit,
  title,
  description,
  formWidth,
  bannerImg,
}: FormProps) => {
  const { isMobile } = useBreakpoints();
  const styles = buildStyle({ formWidth: formWidth ?? 640, isMobile });

  const [currentPage, setCurrentPage] = useState<number>(0);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <>
      <div style={styles.formContainer}>
        {bannerImg && <img style={styles.banner} src={bannerImg} />}

        {currentPage === 0 && (
          <FormSection>
            <h2 style={styles.title}>{title}</h2>
            <p style={styles.description}>{description}</p>
          </FormSection>
        )}

        {pages.map((page, idx) => {
          return currentPage === idx ? page : null;
        })}
        <div style={styles.buttonContainer}>
          {currentPage !== 0 && (
            <Button
              text="Back"
              onClick={() => {
                setCurrentPage((currentPage) => currentPage - 1);
                scrollToTop();
              }}
            />
          )}

          {currentPage === pages.length - 1 && (
            <Button text="Submit" onClick={onSubmit} />
          )}

          {currentPage !== pages.length - 1 && (
            <Button
              text="Next"
              onClick={() => {
                setCurrentPage((currentPage) => currentPage + 1);
                scrollToTop();
              }}
            />
          )}
        </div>
      </div>
    </>
  );
};

const buildStyle = ({
  isMobile,
  formWidth,
}: {
  isMobile: boolean;
  formWidth: number;
}): Record<string, CSSProperties> => {
  return {
    formContainer: {
      display: "flex",
      flexDirection: "column",
      gap: 20,
      width: isMobile ? "90%" : formWidth,
      margin: "0px auto",
      paddingTop: 20,
    },
    banner: {
      width: "100%",
      height: 250,
      borderRadius: 10,
      objectFit: "cover",
      boxShadow: boxShadow.card,
    },
    title: {
      margin: 0,
      fontSize: 30,
      fontWeight: 500,
    },
    description: {
      margin: 0,
    },
    buttonContainer: {
      marginBottom: 30,
      width: "100%",
      display: "flex",
      justifyContent: "right",
      columnGap: 10,
    },
  };
};

export default Form;
