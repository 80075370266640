import useBreakpoint from "use-breakpoint";

export const BREAKPOINTS = { mobile: 0, tablet: 768, desktop: 1280 };

const useBreakpoints = () => {
  const { breakpoint } = useBreakpoint(BREAKPOINTS);
  const isMobile = breakpoint === "mobile";
  const isTablet = breakpoint === "tablet";
  const isDesktop = breakpoint === "desktop";
  return { isMobile, isTablet, isDesktop };
};

export default useBreakpoints;
