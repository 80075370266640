import React, { CSSProperties, ReactNode } from "react";
import { boxShadow, colors } from "../../theme";

interface CardProps {
  children: ReactNode;
  style?: CSSProperties;
}
const Card = ({ children, style }: CardProps) => {
  const styles = buildStyle();
  return <div style={{ ...styles.card, ...style }}>{children}</div>;
};

const buildStyle = (): Record<string, CSSProperties> => {
  return {
    card: {
      backgroundColor: colors.foregroundWhite,
      borderRadius: "5px",
      boxShadow: boxShadow.card,
      padding: "12px",
      boxSizing: "border-box",
    },
  };
};

export default Card;
