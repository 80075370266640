import React, { CSSProperties } from "react";

interface TextFieldProps {
  value: string;
  name: string;
  onChange: (value: string) => void;
  label?: string;
  placeholderText?: string;
}

const ShortAnswer = ({
  value,
  name,
  onChange,
  label,
  placeholderText,
}: TextFieldProps) => {
  const styles = buildStyle();
  return (
    <div style={styles.container}>
      <div id={`${label}-label`} style={styles.label}>
        {label}
      </div>
      <input
        style={styles.inputField}
        aria-labelledby={`${label}-label`}
        name={name}
        type="text"
        value={value}
        onChange={(e) => onChange(e.target.value)}
        placeholder={placeholderText}
      ></input>
    </div>
  );
};

const buildStyle = (): Record<string, CSSProperties> => {
  return {
    container: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
    },
    label: {
      fontSize: "16px",
    },
    inputField: {
      marginTop: "5px",
      fontSize: "16px",
      fontWeight: "400",
      padding: 10,
      border: "1px solid #606368",
      borderRadius: 5,
      width: "100%",
      boxSizing: "border-box",
    },
  };
};

export default ShortAnswer;
