import { FormSubmission } from "../../../utils/types";

interface ClassConfirmationProps {
  formSubmission: FormSubmission;
}

const ClassConfirmation = ({ formSubmission }: ClassConfirmationProps) => {
  return (
    <>
      <div>{formSubmission.firstName}</div>
      <div>{formSubmission.lastName}</div>
      <div>{formSubmission.email}</div>
      <div>{formSubmission.phone}</div>
    </>
  );
};

export default ClassConfirmation;
