import React, { CSSProperties, ReactNode } from "react";
import { boxShadow, colors, fontWeight } from "../../theme";
import Button from "./Button";
import DismissIcon from "../../images/icons/xmark-solid.svg";

interface ModalProps {
  children: ReactNode;
  title: string;
  onDismiss: () => void;
}

const Modal = ({ children, title, onDismiss }: ModalProps) => {
  const styles = buildStyle();
  return (
    <div style={styles.modalBackground}>
      <div style={styles.modal}>
        <div style={styles.modalHeader}>
          <div style={styles.title}>{title}</div>
          <div onClick={onDismiss}>
            <Button icon={DismissIcon} type={"secondary"} overridePadding={0} />
          </div>
        </div>
        {children}
      </div>
    </div>
  );
};

const buildStyle = (): Record<string, CSSProperties> => {
  return {
    modalBackground: {
      position: "fixed",
      width: "100vw",
      height: "100vh",
      left: "0px",
      top: "0px",
      backgroundColor: "rgba(80, 80, 80, 0.4)",
      backdropFilter: "blur(3px)",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    modal: {
      minWidth: "500px",
      minHeight: "200px",
      boxShadow: boxShadow.card,
      backgroundColor: colors.foregroundWhite,
      borderRadius: "5px",
      display: "flex",
      flexDirection: "column",
      padding: "20px",
      boxSizing: "border-box",
    },
    modalHeader: {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      marginBottom: "20px",
    },
    title: {
      fontSize: "24px",
      fontWeight: fontWeight.bold,
    },
  };
};

export default Modal;
