import axios from "axios";
import { EmailTemplate } from "../utils/types";

const baseUrl = "https://nailsuitetools.com/api";
//const baseUrl = "http://localhost:3001/api";

// get template by id
export const getEmailTemplate = async (
  templateId: string
): Promise<EmailTemplate | undefined> => {
  try {
    const response = await axios.get(`${baseUrl}/emailtemplates/${templateId}`);
    return {
      id: response.data.id,
      name: response.data.name,
      subject: response.data.subject,
      content: response.data.content,
    };
  } catch (e) {
    console.log(e);
  }
};

// get all templates
export const getAllTemplates = async (): Promise<
  EmailTemplate[] | undefined
> => {
  try {
    const response = await axios.get(`${baseUrl}/emailtemplates`);
    return response.data;
  } catch (e) {
    console.log(e);
  }
};

export const createTemplate = async (
  template: EmailTemplate
): Promise<string | undefined> => {
  try {
    const { subject, content, name } = template;
    const response = await axios.post(`${baseUrl}/emailtemplates`, {
      name,
      subject,
      content,
    });
    return response.data.templateId;
  } catch (e) {
    console.log(e);
  }
};
