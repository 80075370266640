import "./LoadingSpinner.css";

interface LoadingSpinnerProps {
  radius?: number;
  thickness?: number;
}

const LoadingSpinner = ({
  radius = 200,
  thickness = 64,
}: LoadingSpinnerProps) => {
  return (
    <div style={{ width: radius, height: radius }}>
      <svg viewBox="0 0 800 800" xmlns="http://www.w3.org/2000/svg">
        <circle
          cx="400"
          cy="400"
          fill="none"
          r="200"
          stroke-width={thickness}
          stroke="#d3d3d3"
        />
        <circle
          className="spin"
          cx="400"
          cy="400"
          fill="none"
          r="200"
          stroke-width={thickness}
          stroke="#fa92b5"
          stroke-dasharray="650 1400"
          stroke-linecap="round"
        />
      </svg>
    </div>
  );
};

export default LoadingSpinner;
