import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./index.css";
import MainLayout from "./routes/guest/MainLayout";
import ErrorPage from "./ErrorPage";
import DashboardPage from "./routes/admin/DashboardPage";
import AdminLayout from "./routes/admin/AdminLayout";
import ClassConfirmationPage, {
  loader as classConfirmationLoader,
} from "./routes/guest/ClassConfirmationPage";
import ClassRequestForm from "./routes/guest/ClassRequestFormPage";
import FormsAdminPage from "./routes/admin/FormsAdminPage";
import EmailManagerPage from "./routes/admin/emailManager/EmailManagerPage";
import EmailTemplates from "./components/admin/emailManager/EmailTemplates";
import Emails from "./components/admin/emailManager/Emails";
import EmailAttachments from "./components/admin/emailManager/EmailAttachments";
import EmailTemplatesContainer from "./routes/admin/emailManager/EmailTemplatesContainer";

const router = createBrowserRouter([
  {
    path: "/",
    element: <MainLayout />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "form/one-on-one",
        element: <ClassRequestForm />,
      },
      {
        path: "form/one-on-one/:formId",
        loader: classConfirmationLoader,
        element: <ClassConfirmationPage />,
      },
    ],
  },
  {
    path: "admin",
    element: <AdminLayout />,
    errorElement: <ErrorPage />,
    children: [
      {
        index: true,
        element: <DashboardPage />,
      },
      {
        path: "forms",
        element: <FormsAdminPage />,
      },
      {
        path: "emails",
        element: <EmailManagerPage />,
        children: [
          {
            path: "templates",
            element: <EmailTemplatesContainer />,
          },
          {
            path: "send",
            element: <Emails />,
          },
          {
            path: "attachments",
            element: <EmailAttachments />,
          },
        ],
      },
    ],
  },
]);

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
