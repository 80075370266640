import React, { useState, CSSProperties, useEffect } from "react";
import MultiselectUnchecked from "../../../images/icons/Multi-Select-Empty.svg";
import MultiselectChecked from "../../../images/icons/Multi-Select-Selected.svg";

interface ImageChoice {
  imgSrc: string;
  label: string;
  value: string;
}

interface ImageSelectProps {
  question: string;
  choices: ImageChoice[];
  onChange?: (choices: ImageChoice[]) => void;
}

const ImageSelect = ({ question, choices, onChange }: ImageSelectProps) => {
  const styles = buildStyle();

  const [selectedChoices, setSelectedChoices] = useState<number[]>([]);

  useEffect(() => {
    onChange?.(selectedChoices.map((choiceIdx) => choices[choiceIdx]));
  }, [onChange, selectedChoices]);

  return (
    <div style={styles.container}>
      <p style={styles.question}>{question}</p>
      <div style={styles.choicesContainer}>
        {choices.map((choice, idx) => (
          <div
            key={choice.value}
            style={styles.choiceContainer}
            onClick={() => {
              if (selectedChoices.includes(idx)) {
                setSelectedChoices((current) =>
                  current.filter((choiceIdx) => choiceIdx !== idx)
                );
              } else {
                setSelectedChoices((current) => [...current, idx]);
              }
            }}
          >
            <img
              src={choice.imgSrc}
              style={{
                ...styles.image,
                ...(selectedChoices.includes(idx) && styles.selectedImage),
              }}
            />
            <div style={styles.labelContainer}>
              <img
                src={
                  selectedChoices?.includes(idx)
                    ? MultiselectChecked
                    : MultiselectUnchecked
                }
                style={styles.checkbox}
              />
              <p style={styles.choiceLabel}>{choice.label}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const buildStyle = (): Record<string, CSSProperties> => {
  return {
    container: {
      display: "flex",
      flexDirection: "column",
    },
    question: {
      fontSize: "16px",
      margin: 0,
      marginBottom: 20,
    },
    choicesContainer: {
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      justifyContent: "center",
      gap: "20px 40px",
    },
    choiceContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      paddingLeft: 15,
      marginBottom: 5,
      cursor: "pointer",
      width: "fit-content",
    },
    labelContainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      marginTop: 10,
      maxWidth: 300,
    },
    choiceLabel: {
      margin: "0px 0px",
    },
    image: {
      height: "300px",
      width: "300px",
      borderRadius: 10,
      objectFit: "cover",
    },
    selectedImage: {
      boxShadow:
        "rgba(6, 24, 44, 0.4) 0px 0px 0px 2px, rgba(6, 24, 44, 0.65) 0px 4px 6px -1px, rgba(255, 255, 255, 0.08) 0px 1px 0px inset",
    },
    checkbox: {
      height: 20,
      width: 20,
      marginRight: 10,
    },
  };
};

export default ImageSelect;
