import React, { CSSProperties, useMemo, useState } from "react";
import { colors, fontWeight } from "../../theme";

interface ListProps {
  listItems: { id: string; label: string }[];
  listTitle?: string;
  onClickItem?: (id: string) => void;
  hasActiveItem?: boolean;
  sort?: boolean;
}

const List = ({
  listItems,
  listTitle,
  onClickItem,
  hasActiveItem = false,
  sort = true,
}: ListProps) => {
  const styles = buildStyle();

  const [activeItem, setActiveItem] = useState("");

  const sortedItems = useMemo(() => {
    return sort
      ? listItems.toSorted((a, b) => {
          return a.label.localeCompare(b.label);
        })
      : listItems;
  }, [sort, listItems]);

  return (
    <div style={styles.listContainer}>
      {listTitle && <div style={styles.listTitle}>{listTitle}</div>}
      {sortedItems.map((item) => (
        <div
          key={item.id}
          style={
            item.id === activeItem ? styles.activeListItem : styles.listItem
          }
          onClick={() => {
            onClickItem?.(item.id);
            if (hasActiveItem) {
              setActiveItem(item.id);
            }
          }}
        >
          {item.label}
        </div>
      ))}
    </div>
  );
};

const buildStyle = (): Record<string, CSSProperties> => {
  return {
    listContainer: {
      display: "flex",
      flexDirection: "column",
    },
    listTitle: {
      borderBottom: `1px solid ${colors.primaryGray}`,
      padding: "20px 10px",
      fontWeight: fontWeight.bold,
    },
    listItem: {
      borderBottom: `1px solid ${colors.primaryGray}`,
      padding: "20px 10px",
      fontSize: "14px",
      cursor: "pointer",
    },
    activeListItem: {
      borderBottom: `1px solid ${colors.primaryGray}`,
      padding: "20px 10px",
      fontSize: "14px",
      cursor: "pointer",
      backgroundColor: colors.primaryGray,
    },
  };
};

export default List;
