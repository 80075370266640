import axios from "axios";
import { FormSubmission } from "../utils/types";

const baseUrl = "https://nailsuitetools.com/api";
//const baseUrl = "http://localhost:3001/api";

// get form by id
export const getForm = async (formId: string) => {
  try {
    const response = await axios.get(`${baseUrl}/forms/${formId}`);
    return response.data;
  } catch (e) {
    console.log(e);
  }
};

// get all forms
export const getAllForms = async () => {
  try {
    const response = await axios.get(`${baseUrl}/forms`);
    return response.data;
  } catch (e) {
    console.log(e);
  }
};

export const addForm = async (formSubmission: FormSubmission) => {
  try {
    const { firstName, lastName, email, phone } = formSubmission;
    const response = await axios.post(`${baseUrl}/forms`, {
      firstName,
      lastName,
      email,
      phone,
    });
    return response.data.formId;
  } catch (e) {
    console.log(e);
  }
};
