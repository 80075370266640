import React, { useEffect, useState } from "react";
import EmailManager from "../../../components/admin/emailManager/EmailManager";
import { EmailTemplate } from "../../../utils/types";
import { getAllTemplates } from "../../../clients/EmailClient";

const EmailManagerPage = () => {
  return (
    <>
      <EmailManager />
    </>
  );
};

export default EmailManagerPage;
