import { CSSProperties } from "react";
import { Outlet } from "react-router-dom";

export default function MainLayout() {
  const styles = buildStyle();
  return (
    <div style={styles.contentContainer}>
      <Outlet />
    </div>
  );
}

const buildStyle = (): Record<string, CSSProperties> => {
  return {
    contentContainer: {
      backgroundColor: "#ffc1d6",
      height: "100%",
      width: "100%",
    },
  };
};
