import React, { useEffect, useState } from "react";
import { EmailTemplate } from "../../../utils/types";
import { getAllTemplates } from "../../../clients/EmailClient";
import EmailTemplates from "../../../components/admin/emailManager/EmailTemplates";

const EmailTemplatesContainer = () => {
  const [emailTemplates, setEmailTemplates] = useState<EmailTemplate[]>();

  useEffect(() => {
    async function fetchData() {
      let response = await getAllTemplates();
      setEmailTemplates(response);
    }
    fetchData();
  }, []);

  return <EmailTemplates templates={emailTemplates || []} />;
};
export default EmailTemplatesContainer;
