import { CSSProperties, ReactNode } from "react";
import { boxShadow } from "../../../theme";

interface FormSectionProps {
  children: ReactNode;
  backgroundColor?: string;
}

const FormSection = ({
  backgroundColor = "white",
  children,
}: FormSectionProps) => {
  const styles = buildStyle(backgroundColor);

  return <div style={styles.formSectionContainer}>{children}</div>;
};

const buildStyle = (backgroundColor: string): Record<string, CSSProperties> => {
  return {
    formSectionContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      gap: 25,
      backgroundColor,
      borderRadius: 10,
      boxShadow: boxShadow.card,
      width: "100%",
      margin: "0px auto",
      padding: 30,
      boxSizing: "border-box",
    },
  };
};

export default FormSection;
