export const colors = {
  primaryPink: "#FF88B1",
  secondaryPink: "#FFB2CC",
  primaryGray: "#D3D3D3",
  backgroundGray: "#F5F7FB",
  foregroundWhite: "#FFFFFF",
  primaryBlack: "#000000",
};

export const boxShadow = {
  card: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
  leftNav: "rgba(99, 99, 99, 0.2) 0px 0px 5px 0px",
};
export const fontWeight = {
  boldest: 700,
  bold: 600,
  mediumBold: 500,
};
