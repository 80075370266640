import React, { useState } from "react";
import Modal from "../../common/Modal";
import Button from "../../common/Button";

const Emails = () => {
  const [isSendEmailModalOpen, setIsSendEmailModalOpen] =
    useState<boolean>(false);

  const sendEmailModal = (
    <Modal
      title="Test Title"
      onDismiss={() => {
        setIsSendEmailModalOpen(false);
      }}
    >
      <div>{"test"}</div>
    </Modal>
  );

  return (
    <>
      <div>{"Send Emails"}</div>
      <Button
        text={"Send Email"}
        onClick={() => {
          setIsSendEmailModalOpen(true);
        }}
      />
      {isSendEmailModalOpen && sendEmailModal}
    </>
  );
};

export default Emails;
