import React, { useState, CSSProperties, useEffect } from "react";
import MultipleChoiceUnchecked from "../../../images/icons/Multiple-Choice-Empty.svg";
import MultipleChoiceChecked from "../../../images/icons/Multiple-Choice-Selected.svg";
import useBreakpoints from "../../../utils/hooks/useBreakpoints";

interface Choice {
  label: string;
  value: string;
}

interface MultipleChoiceProps {
  question: string;
  choices: Choice[];
  onChange?: (choice: Choice) => void;
}

const MultipleChoice = ({
  question,
  choices,
  onChange,
}: MultipleChoiceProps) => {
  const { isMobile } = useBreakpoints();
  const styles = buildStyle(isMobile);

  const [selectedChoice, setSelectedChoice] = useState<number | null>(null);

  useEffect(() => {
    if (selectedChoice) {
      onChange?.(choices[selectedChoice]);
    }
  }, [onChange, selectedChoice]);

  return (
    <div style={styles.container}>
      <p style={styles.question}>{question}</p>
      {choices.map((choice, idx) => (
        <div
          key={choice.value}
          style={styles.choiceContainer}
          onClick={() => {
            setSelectedChoice(idx);
          }}
        >
          <img
            src={
              selectedChoice === idx
                ? MultipleChoiceChecked
                : MultipleChoiceUnchecked
            }
            style={styles.checkbox}
          />
          <p style={styles.choiceContent}>{choice.label}</p>
        </div>
      ))}
    </div>
  );
};

const buildStyle = (isMobile: boolean): Record<string, CSSProperties> => {
  return {
    container: {
      display: "flex",
      flexDirection: "column",
    },
    question: {
      fontSize: "16px",
      margin: 0,
      marginBottom: 10,
    },
    choiceContainer: {
      display: "flex",
      height: isMobile ? "auto" : 30,
      alignItems: "center",
      marginBottom: 10,
      cursor: "pointer",
      width: "fit-content",
    },
    choiceContent: {
      margin: 0,
    },
    checkbox: {
      height: 20,
      width: 20,
      marginRight: 10,
    },
  };
};

export default MultipleChoice;
